import * as React from 'react';
import { Panel } from '../../../layout/Panel';
import HorizontalBarChart from './../../../layout/components/HorizontalBarChart';
import PieChartWithNeedle from '../../../layout/components/PieChartWithNeedle';

const Tile = ({title, description, value, scoreValue}) => {
    return(
        <div className="p-4 flex-1 bg-white text-gray-600 rounded border border-primary-30 shadow-shadow-base text-left">
            <div className="flex flex-col">
                <div>
                <HorizontalBarChart name={description} value={scoreValue} width={300} height={100}/> 
                </div>
                <div className="ml-2">
                    <div className="p-2 text-xl font-bold">{title}: {value}</div>
                    <p className="pl-2 text-sm text-gray-600 mb-4">{description}</p>
                </div>
            </div>
        </div>
    )
}

export const RiskTab =({risk1, risk2, symbol}) => {
    const settings = {
        width: 100,
        height: 100,
        startAngle: -110,
        endAngle: 110
    };
    const data = [
        { name: 'A', value: 20, color: '#FF0000' }, // Red
        { name: 'B', value: 20, color: '#FF7F00' }, // Orange
        { name: 'C', value: 20, color: '#FFFF00' }, // Blue
        { name: 'D', value: 20, color: '#7FFF00' }, // Light Green
        { name: 'E', value: 20, color: '#00CC00' }, // Green
    ];

      // 0-AMZN 1-GOOG
    const riskData = [
        [
            { name: '2017', scope1: 0.000, scope2: 0.000, scope3: 0.000, scope123: 0.000 },
            { name: '2018', scope1: 0.961, scope2: 0.909, scope3: 6.699, scope123: 8.569 },
            { name: '2019', scope1: 1.112, scope2: 1.062, scope3: 7.703, scope123: 9.876 },
            { name: '2020', scope1: 1.857, scope2: 1.017, scope3: 8.830, scope123: 11.704 },
            { name: '2021', scope1: 2.386, scope2: 0.802, scope3: 10.906, scope123: 14.093 },
            { name: '2022', scope1: 2.664, scope2: 0.628, scope3: 10.856, scope123: 14.148 },
            { name: '2023', scope1: 2.911, scope2: 0.569, scope3: 10.559, scope123: 14.039 },
            { name: '2024', scope1: 3.600, scope2: 0.452, scope3: 11.670, scope123: 15.722 },
            { name: '2030', scope1: 6.82, scope2: 0.00, scope3: 17.99, scope123: 24.81 },
            { name: '2040', scope1: 13.40, scope2: 0.00, scope3: 30.72, scope123: 44.12 },
            { name: '2050', scope1: 21.79, scope2: 0.00, scope3: 46.79, scope123: 68.57 },
        ],[
            { name: '2017', scope1: 0.013, scope2: 0.637, scope3: 0.525, scope123: 1.175 },
            { name: '2018', scope1: 0.012, scope2: 0.839, scope3: 2.756, scope123: 3.607 },
            { name: '2019', scope1: 0.016, scope2: 0.988, scope3: 1.690, scope123: 2.693 },
            { name: '2020', scope1: 0.011, scope2: 1.132, scope3: 1.467, scope123: 2.610 },
            { name: '2021', scope1: 0.013, scope2: 1.296, scope3: 1.751, scope123: 3.059 },
            { name: '2022', scope1: 0.018, scope2: 1.609, scope3: 2.007, scope123: 3.634 },
            { name: '2023', scope1: 0.016, scope2: 1.888, scope3: 2.206, scope123: 4.109 },
            { name: '2024', scope1: 0.018, scope2: 2.075, scope3: 2.327, scope123: 4.419 },
            { name: '2030', scope1: 0.02, scope2: 3.61, scope3: 3.37, scope123: 7.00 },
            { name: '2040', scope1: 0.03, scope2: 6.74, scope3: 5.45, scope123: 12.22 },
            { name: '2050', scope1: 0.05, scope2: 10.71, scope3: 8.07, scope123: 18.83 },
        ]
    ]
    let graphData = []
    if (symbol == "GOOGL") {
        graphData = riskData[1]
    } else if (symbol == "AMZN") {
        graphData = riskData[0]
    }

    const totalRisk = risk1 + risk2
    const indicators = [
        {
            "title": "Corporate Risk",
            "description": "Corporate Risk",
            "value": risk1,
            "scoreValue": risk1,
        },{
            "title": "Market Risk",
            "description": "Market Risk",
            "value": risk2,
            "scoreValue": risk2
        }]
    return(
        <div className="flex flex-col lg:flex-row gap-4"> 
            <div className="w-full lg:w-2/5 h-full">
                <Panel>
                    <h2 className="text-center text-2xl font-bold text-gray-600">Risk Score: {totalRisk}</h2>
                    <PieChartWithNeedle data={data} width={240} height={240} value={(totalRisk *.24 + 2) *25}/>                
                </Panel>
            </div>
            <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-4">
                {indicators.map((indicator, index) => {
                    return <Tile 
                        key={index} 
                        title={indicator.title} 
                        description={indicator.description}
                        value={indicator.value} 
                        scoreValue={indicator.scoreValue}
                        icon={indicator.icon}
                        industryAverage={indicator.industryAverage}
                        industryMin={indicator.industryMin}
                        industryMax={indicator.industryMax}
                        />
                })}
            </div>
        </div>
    )
}