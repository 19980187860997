import React from "react";

import { BestPerformers } from "./components/BestPerformers.jsx";
import { RecentActivity } from "./components/RecentActivity.jsx";
import { RecentTransactions } from "./components/RecentTransactions.jsx";
import { News } from "./components/News.jsx";
import { Tiles } from "./components/Tiles.jsx";
import { Search } from "./components/Search.jsx";

export const Dashboard = () => {
  return (
    <div className="p-2 lg:p-8">
      <div className="text-2xl font-bold mb-8">Dashboard</div>
      {/* <Tiles/> */}
      <div className="grid">
        <BestPerformers />
      </div>
    </div>
  );
};
