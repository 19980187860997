import * as React from 'react';
import { useState } from 'react';
import { Panel } from '../../../layout/Panel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PieChartWithNeedle from '../../../layout/components/PieChartWithNeedle';
import HorizontalBarChart from './../../../layout/components/HorizontalBarChart';


const Tile = ({title, description, value, scoreValue, industryAverage, industryMin, industryMax}) => {
    return(
        <div className="p-4 flex-1 bg-white text-gray-600 rounded border border-primary-30 shadow-shadow-base text-left">
            <div className="flex flex-col">
                <div>
                <HorizontalBarChart name={description} value={scoreValue} width={280} height={100}/> 
                </div>
                <div className="ml-2">
                    <div className="p-2 text-xl font-bold">{title}: {value}</div>
                    <p className="pl-2 text-sm text-gray-600 mb-4">{description}</p>
                    <p className="pl-2 text-sm text-gray-600">Industry High: {industryMax}</p>
                    <p className="pl-2 text-sm text-gray-600">Industry Average: {industryAverage}</p>
                    <p className="pl-2 text-sm text-gray-600">Industry Low: {industryMin}</p>
                </div>
            </div>
        </div>
    )
}

export const FinanceTab =({company}) => {
    const data = [
        { name: 'A', value: 20, color: '#FF0000' }, // Red
        { name: 'B', value: 20, color: '#FF7F00' }, // Orange
        { name: 'C', value: 20, color: '#FFFF00' }, // Blue
        { name: 'D', value: 20, color: '#7FFF00' }, // Light Green
        { name: 'E', value: 20, color: '#00CC00' }, // Green
    ];
    const indicators = [
        {
            "title": "P/E",
            "description": "Price over Earnings Ratio",
            "value": company.pe_ratio + ' | Score: ' + company.financial_pe_score,
            "scoreValue": company.financial_pe_score,
            "industryMin": company.industry?.pe_lower_value,
            "industryMax": company.industry?.pe_upper_value,
            "industryAverage": company.industry?.pe_average_value,
            "icon": <TrendingUpIcon style={{ fontSize: 40, color: "#1E5EFF" }}/>
        },
        {
            "title": "A/E",
            "description": "Assets over Equity Ratio",
            "value": company.ae_ratio + ' | Score: ' + company.financial_ae_score,
            "scoreValue": company.financial_ae_score,
            "industryMin": company.industry?.ae_lower_value,
            "industryMax": company.industry?.ae_upper_value,
            "industryAverage": company.industry?.ae_average_value,
            "icon": <MonetizationOnIcon style={{ fontSize: 40, color: "#1E5EFF" }}/>
        },{
            "title": "P/V",
            "description": "Profit over Volume Ratio",
            "value": company.pv_ratio + ' | Score: ' + company.financial_pv_score,
            "scoreValue": company.financial_pv_score,
            "industryMin": company.industry?.pv_lower_value,
            "industryMax": company.industry?.pv_upper_value,
            "industryAverage": company.industry?.pv_average_value,
            "icon": <MonetizationOnIcon style={{ fontSize: 40, color: "#1E5EFF" }}/>
        },{
            "title": "Sentiment",
            "description": "Market Sentiment",
            "value": company.recommendation + ' | Score: ' + company.financial_recommendation_score,
            "scoreValue": company.financial_recommendation_score,
            "industryMin": company.industry?.recommendation_lower_value,
            "industryMax": company.industry?.recommendation_upper_value,
            "industryAverage": company.industry?.recommendation_average_value,
            "icon": <MonetizationOnIcon style={{ fontSize: 40, color: "#1E5EFF" }}/>
        }]

    const financialScore = company.financial_pe_score + company.financial_ae_score + company.financial_pv_score + company.financial_recommendation_score;
    return(
        <div className="flex flex-col lg:flex-row gap-4"> 
            <div className="w-full lg:w-2/5 h-full">
                <Panel>
                    <h2 className="text-center text-2xl font-bold text-gray-600">Financial Score: {financialScore}</h2>
                    <PieChartWithNeedle data={data} width={240} height={240} value={(financialScore *.24 + 2) *25}/>                
                </Panel>
            </div>
            <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-4">
                {indicators.map((indicator, index) => {
                    return <Tile 
                        key={index} 
                        title={indicator.title} 
                        description={indicator.description}
                        value={indicator.value} 
                        scoreValue={indicator.scoreValue}
                        icon={indicator.icon}
                        industryAverage={indicator.industryAverage}
                        industryMin={indicator.industryMin}
                        industryMax={indicator.industryMax}
                        />
                })}
            </div>
        </div>
    )
}